.add-edit-note-container {
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-top: 10px;
}

.add-edit-note-controls {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 30px;
}