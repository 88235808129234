@import "../../shared/styles/variables.scss";

.title-container {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Primary Blue/800 */

    color: $primary-blue;
    user-select: none;
}