@import "../../../../shared/styles/variables.scss";

.send-button {
    width: 98px;
}

.send-button.disabled {
    color: $neutral-600;
    background-color: $neutral-300;
}

.send-button.active {
    color: white;
    background-color: $primary-blue;
    &:hover {
        background-color: $primary-blue-400;
    }
}

.cancel-button {
    width: 98px;
    background-color: $neutral-300;
    color: $neutral-600;
    margin-left: 10px;
    &:hover {
        background-color: $neutral-400;
        color: $neutral-100;
    }
}