@import "../../../../../shared/styles/variables.scss";

.horizontal-rule-container {
    height: 1px;
    border-bottom: 1px solid $neutral-400;
    margin: 16px 0 16px 0;
}

.dashed {
    border-bottom: 1px dashed $neutral-400;
}