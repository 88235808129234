@import "../../shared/styles/variables.scss";

.wysiwyg-container {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: $neutral-800;
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    border: 1px solid $neutral-400;
    border-radius: 8px;
    
    padding: 10px;
    margin-top: 10px;
}

.wysiwyg-container.readOnly {
    padding: 0px;
    border: none;
    margin-top: 0px;
}

.wysiwyg-controls {
    display: flex;
    margin-bottom: 15px;
}

.control-group {
    display: flex;
    margin-right: 10px;
}

.wysiwyg-textArea {
    border: 1px solid black;
    resize: none;
    width: 100%;
    max-width: 100%;
    margin-top: 8px;
}

div.DraftEditor-root {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    z-index: 0 !important;
}
