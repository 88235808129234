@import '../../../../shared/styles/variables.scss';

.infusion-history-row-container {
  .history-actions-container {
    display: flex;
    justify-content: center;
  }

  .square-icon-button {
    border-radius: 8px;
  }

  .cancel-button {
    border: 1px solid $neutral-400;
    background-color: $neutral-50;
    margin-left: 0px;
  }

  .save-button {
    background-color: $primary-blue-400;
    margin-left: 16px;
  }

  .edit-button-enabled {
    border: 1px solid $neutral-400;
    background-color: $neutral-50;
  }
}
