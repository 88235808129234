@import "../../../../shared/styles/variables.scss";

.icon-button {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.icon-button:hover {
    color: $neutral-700;
}
