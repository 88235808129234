@import "../../../../../shared/styles/variables.scss";

.toggle-button-container {
    display: flex;

    .button {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        border: 2px solid transparent;
        padding: "16px";
        border-radius: 0px;
    }
    
    .active {
        //styleName: Paragraph/Small/Bold;
        color: $primary-blue-400;
        border-bottom: 2px solid $primary-blue-400;
    }

    .inactive {
        color: $neutral-400;
    }

}