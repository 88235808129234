@import "../../../../src/shared/styles/variables.scss";

.spinner-icon {
  position: relative;
  color: $primary-blue;
  transform-origin: 50% 50%;
  width: 50px;
}

.rotate {
  animation: rotation 1.5s infinite linear;
}

.loading-list-container {
  color: $primary-blue;
  display:flex;
  justify-content: center;
}

.loading-list-spinning-icon {
  margin-top: 16px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}