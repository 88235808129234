@import "../../shared/styles/variables.scss";

.button {
  align-items: center;
  justify-content: space-evenly;
  height: 48px;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  line-height: 1;
  width: 158px;
}

.disabled {
  color: $neutral-400;
  background-color: $neutral-300;
}
