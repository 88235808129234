@import '../../shared/styles/variables.scss';

.style-control-container {
    padding: 5px;
    border: 1px solid $neutral-400;
    margin-right: 3px;
    color: $neutral-400;
    display: flex;
    align-items: center;
    
    .active {
        color: $neutral-800;
    }

    &:hover {
        cursor: pointer;
        color: $neutral-800;
        border: 1px solid $neutral-800;
    }
}

