@import "../../shared/styles/variables.scss";

.idle-popup-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;

  .idle-popup-parent {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 290px;
    margin: 0 auto;
    margin-top: 10%;
    padding: 32px 0 32px;
    width: 475px;

    .popup-header {
      color: $neutral-grey;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    .popup-body-text {
      font-size: 18px;
    }

    .popup-countdown {
      color: $warning;
      font-size: 22px;
      font-weight: 700;
    }

    .popup-buttons {
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      .logout-button {
        background-color: $white;
        border: 1px solid $active-blue;
        color: $active-blue;
        font-size: 16px;
      }

      .continue-button {
        background-color: $active-blue;
        color: $white;
        font-size: 16px;
      }
    }
  }
}
