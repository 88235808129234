// colors
$active-blue: #2cc0e8;
$grey: #c5c5c5;
$light-grey: #e7e7e7;

$neutral-50: #fbfbfb;
$neutral-100: #f6f6f6;
$neutral-200: #f1f1f1;
$neutral-300: #e7e7e7;
$neutral-400: #c5c5c5;
$neutral-600: #7d7d7d;
$neutral-700: #686868;
$neutral-800: #494949;
$neutral-900: #272727;

$neutral-grey: #494949;
$primary-blue: #0091b2;
$primary-blue-400: #2cc0e8;
$primary-blue-500: #1ab5e1;
$success-green: #359d36;
$teal-blue: #007e99;
$warning: #e7800b;
$white: #fff;
$black: #000;

$gradient-blue: #0291b1;
$gradient-green: #87b880;

// shadows and gradients
$navbar-box-shadow: 0px 4px 16px rgba(39, 39, 39, 0.1);
