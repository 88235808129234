@import "../../shared/styles/variables.scss";

.navbar-container {
  align-items: center;
  background-color: $white;
  border-bottom: 0.5px solid $grey;
  box-shadow: $navbar-box-shadow;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  width: 100%;

  .time-container {
    padding-right: 5%;
    width: 70px;
  }

  .nav-dropdown {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding-left: 5%;
    width: 166px;

    .infusion-dropdown {
      color: $primary-blue;
      font-weight: 700;
    }
  }

  .navbar-item {
    display: flex;
    align-items: center;
    min-width: 100px;

    .nbs-status {
      color: $success-green;
      padding-left: 12px;
    }
  }
}
