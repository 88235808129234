@import "../../../shared/styles/variables.scss";

.patient-infusion-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  .patient-infusion-content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-items: stretch;
  }

  .infusion-tracker-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .infusion-time-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
  }

  .clock {
    display: flex;
    justify-content: center;
    color: $neutral-900;
    font-weight: 700;
    font-size: 68px;
    padding-bottom: 16px;
  }

  .timer {
    display: flex;
    justify-content: center;
    color: $neutral-600;
    font-weight: 400;
    font-size: 22px;
  }

  .infusion-controls-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-content: flex-end;
    margin-right: 32px;
  }

  .edit-start-time-container {
    display: flex;
  }

  .edit-end-time-container {
    display: flex;
  }

  .start-container {
    display: flex;
    padding-bottom: 36px;
    justify-content: flex-end;
  }

  .end-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .end-inputs-container {
    display: flex;
    justify-content: flex-end;
  }

  .edit-container {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
  }

  .square-icon-button {
    border-radius: 8px;
  }

  .edit-button-enabled {
    border: 1px solid $neutral-400;
    background-color: $neutral-50;
  }

  .cancel-button-container {
    padding-right: 16px;
  }

  .cancel-button {
    border: 1px solid $neutral-400;
    background-color: $neutral-50;
  }

  .save-button {
    background-color: $primary-blue-400;
  }

  .infusion-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 4px;
  }

  .infusion-button-enabled {
    color: $neutral-50;
    background-color: $primary-blue-400;
  }

  .show-add-button {
    padding-bottom: 24px;
  }

  .add-session-container {
    display: flex;
    justify-content: flex-end;
  }

  .add-session-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid $neutral-400;
    border-radius: 10px;
    color: $primary-blue-400;
    background-color: $neutral-50;
  }

  .line-break {
    border: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(to left, #0091b2, #89b880);
    width: 100%;
    margin-top: 50px;
    margin-bottom: 16px;
  }

  .title {
    padding: 1rem;
    color: $neutral-800;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 32px;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  .history-table-container {
    display: flex;
    flex-direction: "column";
    align-items: "flex-start"
  }

  .table-container {
    background-color: white;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .body-container {
      display: block;
      width: 100%;
      margin-top: 2px;
      overflow-y: auto;
      max-height: 55vh;
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        padding-right: 10px;
        border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
