@import "../../shared/styles/variables.scss";

.sidebar-container {
  align-items: center;
  background-color: $teal-blue;
  box-shadow: $navbar-box-shadow;
  height: 100%;
  max-width: 224px;
  min-width: 224px;
  transition: all ease-in-out 0.3s;
  width: 17%;

  .sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: 224px;

    .sidebar-header {
      align-items: center;
      color: $white;
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      width: 100%;

      .tae-logo {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        .carrot {
          height: 25px;
          padding-left: 40px;
          transition: all ease-in-out 0.1s;
          width: 25px;
        }

      }

      .sidebar-button {
        align-items: center;
        background-color: $primary-blue;
        box-shadow: $navbar-box-shadow;
        color: $white;
        display: flex;
        font-weight: 700;
        height: 56px;
        justify-content: space-evenly;
        margin-bottom: 16px;
        width: 85%;

        .infusion-button-icon {
          color: $white;
        }

        .infusion-button-text {
          animation: inAnimation 0.8s ease-in;
          padding-left: 12px;
        }
      }

      .sidebar-button:hover {
        background-color: $primary-blue-500;
      }
    }

    .sidebar-footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      width: 100%;

      .logged-in-user {
        animation: inAnimation 0.8s ease-in;
        color: $white;
        font-weight: 700;
        padding-bottom: 50px;
        transition-delay: 2s;
      }

      .login-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

// Collapsed sidebar state
.sidebar-container-collapsed {
  align-items: center;
  background-color: $teal-blue;
  box-shadow: $navbar-box-shadow;
  height: 100%;
  width: 84px;
  transition: all ease-in-out 0.3s;

  .sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .sidebar-header {
      align-items: center;
      color: $white;
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      width: 100%;

      .tae-logo {
        align-items: center;
        display: flex;
        flex-direction: column;

        .carrot {
          padding: 21px 21px;
          transform: rotate(180deg);
          transition: all ease-in-out 0.1s;
        }
      }

      .sidebar-button {
        align-items: center;
        background-color: $primary-blue;
        box-shadow: $navbar-box-shadow;
        display: flex;
        height: 56px;
        justify-content: center;
        margin-bottom: 16px;
        width: 70%;
      }

      .sidebar-button:hover {
        background-color: $primary-blue-500;
      }
    }

    .sidebar-footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;
      width: 100%;

      .logged-in-user {
        color: $white;
        font-weight: 700;
        padding-bottom: 50px;
        text-align: center;
        transition-delay: 2s;
      }
    }
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
