@import "../../../../../shared/styles/variables.scss";

.plan-overview-container {
    display: flex;
    flex-direction: column;

    .title {
        //styleName: Heading/H6/Small/Bold;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        color: $neutral-800;
        margin: 16px 0 16px 0;
    }

    .subtitle {
        //styleName: Paragraph/Small/Bold;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: $neutral-700;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .plan-data {
        flex-grow: 1;
        margin:8px;

    }

    .data-group {
        display: flex;
        align-items: center;

        .label-and-data {
            display: flex;
            min-width: 100px;
            margin-bottom: 12px;
            margin-right: 50px;
            align-items: center;

            .label {
                //styleName: Paragraph/Small/Bold;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0em;
                min-width: 150px;
                margin-right: 16px;
            }

            .data {
                //styleName: Paragraph/Small/Regular;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                width: 170px;
                margin-right: 16px;
            }
            .input {
                    padding: 8px;
                    border: 1px solid $neutral-300;
                    border-radius: 4px;
                    outline: none;
                    font-size: 16px;
                    width: 170px;
                }

            .approval-required {
                color: red;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .beam-data {
        display: flex;
        flex-direction: column;
        padding: 16px;
        border: 1px solid $neutral-300;
        border-radius: 12px;
        margin-bottom: 16px;
    }

    .controls {
        display: flex;
        justify-content: flex-end;

        .approve-button {
            background-color: $primary-blue-400;
            color: white;
            margin-right: 16px;
        }

        .edit-button {
            background-color: white;
            color: $primary-blue-400;
            border: 1px solid $primary-blue-400;
        }

        .save-button {
            background-color: $primary-blue-400;
            color: white;
        }

        .cancel-button {
            background-color: white;
            color: $primary-blue-400;
            border: 1px solid $primary-blue-400;
            width: 140px;
        }
    }

}

