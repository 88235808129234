@import '../../shared/styles/variables.scss';
.card {
  padding: 1rem;
  background: #fff;
  box-shadow: $navbar-box-shadow;
  border-radius: 0.5rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.card-title-top {
  padding: 1rem;
  background: -webkit-linear-gradient(left, $gradient-blue, $gradient-green);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 18px;
}

.card-body {
  display: flex;
}

.card-header {
  display: flex;
  justify-content: space-between;
}
