@import "../../../../shared/styles/variables.scss";

.treatment-plans-list-container {
    display:flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.title {
    //styleName: Paragraph/Standard/Bold;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    color: $neutral-700;
    text-transform: uppercase;
    margin-bottom: 16px;
    margin-top: 16px;
}

.search-plans-container {
    display: flex;
    margin-bottom: 32px;

    .reset-filters-button {
        margin-left: 16px;
    }
    
    .active-reset-button {
        color: white;
        background-color: $primary-blue-400;
    }

    .inactive-reset-button {
        color: $neutral-400;
        background-color: $neutral-300;
    }
}