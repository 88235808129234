@import "../../shared/styles/variables.scss";

.temporary-login-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;

  .temporary-login-parent {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 290px;
    margin: 0 auto;
    margin-top: 10%;
    padding: 32px 0 32px;
    width: 475px;

    .temporary-login-header {
      color: $neutral-grey;
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    .popup-buttons {
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      .login-button {
        background-color: $white;
        border: 1px solid $active-blue;
        color: $active-blue;
        font-size: 16px;
      }
    }
  }
}
