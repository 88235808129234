@import '../../../../shared/styles/variables.scss';

.header-cell-container {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: $neutral-800;
    /* identical to box height */
    flex: 1;
    display: flex;
    .center {
        justify-content: center;
    }
    .left {
        justify-content: flex-start;
    }
    .right {
        justify-content: flex-end;
    }
}

.header-cell-content {
    display: flex;
    flex-grow: 1;
}