@import "./shared/styles/variables.scss";

body {
  margin: 0;
  padding: 0;
}

.App {
  display: block;
  font-family: "Montserrat", "Roboto", sans-serif;

  .app-content {
    display: flex;
    background-color: $light-grey;
    height: 100vh;

    .page-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow-y: scroll;
      flex-grow: 1;

      .main-content {
        display: flex;
        align-items: stretch;
        flex-grow: 1;
      }
    }
  }
}
