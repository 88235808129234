@import '../../../../shared/styles/variables.scss';

.notes-card {
  display: flex;
  flex-direction: column;
 
}

.notes-container {  
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border: 1px solid $neutral-400;
  border-radius: 12px;
  padding: 16px 12px 16px 16px;
}

.notes-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    padding-right: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.notes-item {
  display: flex;
  position: relative;
  flex-direction: column;
  color: $neutral-800;
  background-color: $neutral-100;
  border: 1px solid $neutral-100;
  border-radius: 8px;
  margin: 0px 24px 8px 0px;
  padding: 16px 16px 16px 16px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.notes-item.highlighted {
  border: 1px solid $primary-blue
}

.note-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.note-header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.note-author {
  font-weight: 700;
}
.note-date,
.note-time,
.note-edited {
  margin-left: 16px;
  margin-right: 16px;
  color: $neutral-600;
  font-weight: 400;
}

.note-not-edited {
  display: none;
}

.note-edited {
  position: relative;
  display: inline-block;
}

.note-edited .note-edited-tooltip {
  visibility: hidden;
  color: $neutral-400;
  background-color: $black;
  text-align: center;
  width: 170px;
  padding: 5px 5px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  margin-top: -5px;
}
.note-edited:hover .note-edited-tooltip {
  visibility: visible;
  opacity: 1;
}

.note-body {
  margin-top: 18px;
  font-weight: 400;
  max-width: 1480px;
}

.edit-controls {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.control-button {
  color: $neutral-400;
  background-color: transparent;  
  &:hover {
    color: $primary-blue
  }
}

.control-button.highlighted {
    color: $primary-blue;
    &:hover {
      color: $primary-blue-400
    }
}


