@import '../../shared/styles/variables.scss';

.subnav-container {
  background-color: $white;
  box-shadow: $navbar-box-shadow;
  height: 48px;
  width: 100%;

  .subnav-tab {
    align-items: center;
    color: $grey;
    display: flex;
    gap: 1rem;
    height: 98%;
    justify-content: center;
    margin-left: 5rem;
    width: 84px;

    .subnav-tab-title {
      align-self: center;
      font-weight: 700;
      padding: 1rem;
    }

    .subnav-tab-close {
      margin-left: 5px;
      color: inherit;
      text-decoration: none;
    }
  }
}
