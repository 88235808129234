@import "../../../../shared/styles/variables.scss";

.reset-filters-button-container {
    display: flex;
    align-items: center;
    width: 149px;
    height: 40px;
    justify-content: space-between;
    padding: 8px 12px;
    text-transform: capitalize;
    background-color: $primary-blue;
    color: $white;
    user-select: none;
    cursor: pointer;
}

.disabled {
    background-color: $neutral-300;
    color: $neutral-400;
    cursor:default
}

.hover {
    background-color: $primary-blue-500;
}