@import "../../../shared/styles/variables.scss";

.search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    border: 1px solid;
    border-color: $neutral-400;
    border-radius: 100px;
    margin-right: 30px;
    flex-grow: 1;
}

.search-input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Neutral/400 */

    color: $neutral-400;
    box-sizing: border-box;
    outline: none;
    border: none;
    color: $black;
}

.search-input:focus {
    outline: none;
}