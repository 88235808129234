@import "../../shared/styles/variables.scss";

.text-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 10px;
  border: 1px solid;
  border-color: $neutral-400;
  border-radius: 4px;
  background: $neutral-50;
}

.text-input-container.disabled {
  background-color: $neutral-300;
  border-color: $neutral-300;
}

.text-input-container.readOnly {
  cursor: default;
}

.text-input-container.highlightBorder {
  border-color: $primary-blue-400;
}

.text-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  width: 100%;

  color: $neutral-800;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: $neutral-50;
}

.text-input {
  &:focus {
    &:placeholder {
      color: transparent;
    }
  }
}

.text-input.disabled {
  background-color: $neutral-300;
  color: $neutral-400;
}

.text-input.readOnly {
  cursor: default;
}

.text-input.disabled::placeholder {
  color: $neutral-400;
}
