@import "../../../../../shared/styles/variables.scss";

.SingleDatePicker {
  position: relative;
}

.DayPicker {
  position: absolute;
  z-index: -1;
  border: 1px solid;
  border-color: $neutral-400;
  border-radius: 4px; 
  font-family: 'Roboto';
  color: $neutral-900;
};

.DayPickerKeyboardShortcuts_panel {
    display: none;
};

.DayPicker_weekHeader {
    color: black;
};

.DayPickerKeyboardShortcuts_buttonReset{
    display: none;
};

.DayPickerNavigation_button {
    background: $teal-blue;
};

.DayPickerNavigation_svg__horizontal {
    fill: white;
}

.DateInput_input {
  color: black;
  background: $neutral-50;
  border: 1px solid;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid $neutral-400;

};
.DateInput_input__small {
    font-size: 16px;
    line-height: 19px;
    padding: 9px;
}
.card-title {
  overflow:hidden;
  color:black;
  z-index: -1;
}

.CalendarDay__default {
  border: 1px solid $neutral-400;
};
