.treatment-plans-card {
  width: 100%;

}

.treatment-plans-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    padding-left: 16px;
}