@import "../../shared/styles/variables.scss";

.content-panel-container {
  display: flex;
  align-items: stretch;
  background-color: $white;
  border-radius: 12px;
  margin: 16px;
  flex-grow: 1;
}
