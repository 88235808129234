@import "../../shared/styles/variables.scss";

.privacy-container {
    backdrop-filter: blur(10px) brightness(0.75);
    border-radius: 10px;
    overflow: hidden;

    font-family: 'Montserrat';
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    
    display: flex;
    align-items:flex-end;
    align-content: space-between;
    text-align: center;
    flex-direction: column;
    justify-content: right;
    padding: 16px;
    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.privacy-button {
    align-items: center;
    background-color: $primary-blue;
    box-shadow: $navbar-box-shadow;
    color: $white;
    display: flex;
    font-weight: 700;
    height: 56px;
    justify-content: space-evenly;
    width: 350px;
    border: 2px solid white;

    .infusion-button-icon {
      color: $white;
    }

    .infusion-button-text {
      animation: inAnimation 0.8s ease-in;
      padding-left: 12px;
    }
  }
