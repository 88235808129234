@import "../../../../../shared/styles/variables.scss";

.setup-notes-container {
    display: flex;
    flex-grow: 1;
    border: 1px solid $neutral-300;
    border-radius: 16px;
    height: 700px;
    margin-top: 16px;
    
    align-items: center;
    justify-content: center;
    
    font-weight: 700;
    font-size: 40px;
    color: $neutral-600
}